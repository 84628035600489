<template>
	<div>
		<div class="card">
			<div class="card-body">
				<quote />
			</div>
		</div>
	</div>
</template>
<script>
import quote from '@/components/quote'
export default {
	components: {
		quote,
	},
}
</script>