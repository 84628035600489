<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div :class="routes.length ? 'col-md-8' : 'col-md-12'">
						<h4>Dirección</h4>
						<hr class="hrText" data-content="♦" />
						<div class="mb20">
							<a-input type="text" id="search_input" v-model="location" />
						</div>
						<div id="map" style="width: 100%; height: 500px"></div>
					</div>
					<div class="col-md-4" v-if="routes.length">
						<h4>Tarifa</h4>
						<hr class="hrText" data-content="♦" />
						<div>
							<div class="row text-center">
								<div class="col-md-6">
									<b>Distancia</b><br />
									{{ numeral(getDistance).format('0.00') }} Km
								</div>
								<div class="col-md-6">
									<b>Tiempo aprox</b><br />
									{{ numeral(getTravelTime.minutes).format('0.00') }} min <small>({{ numeral(getTravelTime.hours).format('0.00') }} hrs.)</small>
								</div>
							</div>
						</div>
						<div class="text-center">
							<a :href="navigationLinks.waze" target="new">- Link Waze -</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Loader } from '@googlemaps/loader'
import numeral from 'numeral'

const loader = new Loader({
	apiKey: 'AIzaSyCov2muN18SzF5_QjKbjCnS9owgZo62CiI',
	version: 'weekly',
	libraries: ['places'],
})

const mapOptions = {
	zoom: 12,
	center: { lat: 19.4080115, lng: -99.0337011 },
}

const autocompleteOptions = {
	types: ['geocode', 'establishment'],
	componentRestrictions: { country: 'mx' },
	fields: ['address_components', 'geometry', 'icon', 'name'],
	strictBounds: false,
}

export default {
	data() {
		return {
			map: undefined,
			autocomplete: undefined,
			nearPlace: {},
			routes: [],
			location: '',
			uniqueCostDelivery: 8,
			localCost: {
				warehouseManager: 50,
			},
			navigationLinks: {
				waze: '',
			},
		}
	},
	computed: {
		getUniqueCost() {
			return (this.routes[0].legs[0].distance.value / 1000) * this.uniqueCostDelivery
		},
		getUniqueDistance() {
			return this.routes[0].legs[0].distance.value / 1000
		},
		getCost() {
			return (this.routes[0].legs[0].distance.value / 1000) * this.uniqueCostDelivery
		},
		getDistance() {
			return (this.routes[0].legs[0].distance.value / 1000) * 2
		},
		getTravelTime() {
			return {
				minutes: (this.routes[0].legs[0].duration.value / 60) * 2,
				hours: ((this.routes[0].legs[0].duration.value / 60) * 2) / 60,
			}
		},
		getTotalUniqueCost() {
			return this.getUniqueCost + this.localCost.warehouseManager
		},
	},
	mounted() {
		//
		let _this = this

		loader.loadCallback((e) => {
			if (e) {
				console.log(e)
			} else {
				_this.map = new google.maps.Map(document.getElementById('map'), mapOptions)
				_this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('search_input'), autocompleteOptions)

				const directionsService = new google.maps.DirectionsService()
				const directionsDisplay = new google.maps.DirectionsRenderer()

				directionsDisplay.setMap(_this.map)

				_this.autocomplete.setComponentRestrictions({
					country: ['mx'],
				})

				google.maps.event.addListener(_this.autocomplete, 'place_changed', function () {
					_this.nearPlace = _this.autocomplete.getPlace()
					_this.location = _this.nearPlace.name
					// Create waze link
					_this.navigationLinks.waze = `https://www.waze.com/ul?ll=${_this.nearPlace.geometry.location.lat()}%2C${_this.nearPlace.geometry.location.lng()}&navigate=yes&zoom=17`

					calculateAndDisplayRoute(directionsService, directionsDisplay)
				})

				function calculateAndDisplayRoute(directionsService, directionsDisplay) {
					directionsService.route(
						{
							origin: { lat: 19.4080115, lng: -99.0337011 },
							destination: document.getElementById('search_input').value,
							travelMode: 'DRIVING',
						},
						function (response, status) {
							if (status === 'OK') {
								directionsDisplay.setDirections(response)
								_this.routes = response.routes
							}
						},
					)
				}
			}
		})
	},
	methods: {
		numeral,
		callback(key) {
			console.log(key)
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>